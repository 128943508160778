<template>
   <div
      id="app"
      @wheel="handleScroll"
   >
      <div
         id="nav"
         :class="[
            isScrollingDown
               ? 'nave'
               : '',
         ]"
      >
         <div class="imgs">
            <img
               src="./assets/ERP_logo.png"
               alt=""
            />
            <p>南棠科技</p>
         </div>
         <ul>
            <li>
               <router-link
                  to="/Home"
                  >首页</router-link
               >
            </li>
            <li>
               <router-link
                  to="/Pricing"
                  >定价</router-link
               >
            </li>
            <li>
               <router-link
                  to="/AboutUs"
                  >关于我们</router-link
               >
            </li>
            <li>
               <router-link
                  to="/Privacy"
                  >隐私政策</router-link
               >
            </li>
         </ul>
         <div class="but">
            <button>
               登录
            </button>
            <button>
               注册
            </button>
         </div>
      </div>
      <div
         class="left_nave"
         @click="top"
      ></div>
      <router-view />
      <!-- <img
            src="./assets/2-4.png"
            alt=""
         /> -->
   </div>
</template>
<script>
export default {
   data() {
      return {
         isScrollingDown: false,
      };
   },
   mounted() {
      window.addEventListener(
         "scroll",
         this.handleScroll
      );
   },
   methods: {
      handleScroll() {
         const scrollTop =
            window.scrollY; // 获取滚动高度
         this.isScrollingDown =
            scrollTop;
         // 在这里可以根据滚动高度做相应的操作
      },
      beforeDestroy() {
         window.removeEventListener(
            "scroll",
            this.handleScroll
         ); // 在组件销毁前移除滚动事件监听
      },
      top(time = 1000) {},
   },
};
</script>
<style lang="scss">
html,
body {
   margin: 0 !important;
   padding: 0 !important;
   box-sizing: border-box; /* 确保盒模型为border-box */
}
#app {
   font-family: Avenir,
      Helvetica, Arial,
      sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: #2c3e50;
   padding: 0px;
   margin: 0px;
}

#nav {
   position: fixed;
   display: flex;
   top: 0px;
   left: 0px;
   padding: 10px;
   width: 100%;
   height: 50px;
   justify-content: center;
   z-index: 1000;
   ul li {
      list-style: none;
      margin: 0px;
      padding: 0px;
   }
   ul {
      display: flex;
   }
   a {
      display: block;
      width: 100px;
      font-weight: bold;
      color: #bbbbbe;
      text-decoration: none;
      &.router-link-exact-active {
         color: #ffffff;
      }
   }
}
.imgs {
   display: flex;
   img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
   }
   p {
      font-weight: 600px;
      color: #fff;
      font-weight: 550;
   }
}
.but {
   margin-top: 10px;
   & button {
      height: 30px;
      width: 60px;
      border-radius: 5px;
   }
   & button:nth-of-type(1) {
      background-color: #fff;
      border: #1f80fb 1px
         solid;
      color: #1f80fb;
      &:hover {
         background-color: #1f80fb;
         color: #fff;
      }
      margin-right: 5px;
   }
   & button:nth-of-type(2) {
      background-color: #1f80fb;
      border: #1f80fb 1px
         solid;
      color: #ffffff;
   }
}
.nave {
   background-color: #fff;
   box-shadow: 0px 0px 5px 3px
      rgba(0, 0, 0, 0.3);
   .imgs p {
      color: #8dc11e;
      font-weight: 550;
   }
   & > ul > li > a {
      color: #97979e !important;
      &.router-link-exact-active {
         color: #1f80fb !important;
      }
   }
}
.left_nave {
   position: fixed;
   right: 0px;
   top: 150px;
   width: 70px;
   height: 475px;
   background-color: #fff;
   z-index: 1002;
   background: url("./assets/Snipaste_2024-01-08_15-14-49.png")
      no-repeat;
}
</style>
